@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button {
  outline: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: block;
  background: url('./assets/calendar.svg');
  background-repeat: no-repeat;
  cursor: pointer;
}
